import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import { tracker } from '@shared/utils/watracker';

// Sentry.init({
//   dsn: "https://ce66955ec856fc28fe20b8ededb05ee6@sentry.whatsauto.com.br/4",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: [
//         environment.apiUrl,
//         environment.chatFlowAppAddress,
//         environment.audioConverterUrl,
//         environment.CDN_URL,
//       ],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   environment: environment.name,
//   enabled: environment.production,
// });

// tracker.start();

moment.locale('pt-br');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
