import { Component } from '@angular/core';
import { AppDataPipe } from '@shared/pipes/app-data.pipe';
import { AssetsSourcePipe } from '@shared/pipes/assets-source.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'whatsauto-webclient';

  favIcon: HTMLLinkElement = document.querySelector('#appIcon') ?? new HTMLLinkElement();

  changeIcon() {
    this.favIcon.href = this.assetsSource.transform('favicon.ico');
  }

  changeTitle() {
    document.title = this.appData.transform('WhatsAuto');
  }

  constructor(
    private assetsSource: AssetsSourcePipe,
    private appData: AppDataPipe,
  ) {
    this.changeIcon();
    this.changeTitle();
  }
}
