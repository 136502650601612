import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { Channel } from '@shared/entities/channel';
import { Observable, map, catchError, observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseService {
    patchCustomerName(customerId: number, payload: any): Observable<any[]> {
        return this.http
          .patch<any[]>(
            `${this.apiUrl}/api/customer/${customerId}`, payload
          )
          .pipe(map(super.extractData), catchError(super.handleError.bind(this)));
      }

    getAllByName(customerName: string){
      return this.http
      .get<any[]>(
        `${this.apiUrl}/api/customer/name/${customerName}`
      )
      .pipe(map(super.extractData), catchError(super.handleError.bind(this)));
    }

    getByPhone(phoneNumber: any){
      return this.http
      .get<any[]>(
        `${this.apiUrl}/api/customer/phoneNumber/${phoneNumber}`
      )
      .pipe(map(super.extractData), catchError(super.handleError.bind(this)));
    }

    getById(id: number): Observable<any> {
      return this.http
        .get<any>(
          `${this.apiUrl}/api/Customer/${id}`
        )
        .pipe(map(super.extractData), catchError(super.handleError.bind(this)));
    }
}