import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  get(name: string) {
    const data = localStorage.getItem(name);
    return data ? JSON.parse(data) : null;
  }

  getToken() {
    return this.get('token');
  }

  set(name: string, data: any) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  remove(name: string) {
    localStorage.removeItem(name);
  }

  clear() {
    localStorage.clear();
  }
}
