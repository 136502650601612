<header class="tw-flex tw-justify-between tw-max-w-screen-xl tw-px-6 tw-py-4 tw-mx-auto lg:tw-px-8 xl:tw-px-4 lg:tw-py-6">
  <a routerLink="/">
    <span class="tw-sr-only">{{ 'WhatsAuto' | appData }}</span>
    <img [src]="'logo.svg' | assetsSource" [alt]="'WhatsAuto' | appData" class="tw-h-12 tw-w-auto">
  </a>
  <nav class="tw-flex tw-items-center tw-space-x-4">
    <a href="/app/auth/login" class="tw-flex tw-items-center tw-space-x-1 hover:tw-text-gray-500">
      <svg class="tw-hidden tw-w-5 tw-h-5 sm:tw-inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
      <span>Login</span>
    </a>
    <a href="#" class="tw-inline-block tw-px-5 tw-py-2 tw-font-semibold tw-text-white tw-rounded-lg bg-primary hover:bg-primary-focus">
      Criar conta
    </a>
  </nav>
</header>
