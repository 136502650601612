<div class="tw-relative tw-py-12 tw-overflow-hidden tw-bg-gradient-to-b tw-from-gray-50 tw-to-white lg:tw-mb-16 xl:tw-mb-24 lg:tw-pt-20">
  <div class="tw-relative tw-z-20 tw-max-w-screen-xl tw-px-6 tw-mx-auto lg:tw-px-8 xl:tw-px-4">
    <div class="tw-mb-6 tw-text-center md:tw-mb-8 lg:tw-mb-12">
      <h2 class="tw-mb-2 tw-text-3xl tw-font-bold tw-text-gray-800 md:tw-text-4xl lg:tw-text-5xl md:tw-mb-4">FAQ</h2>
      <p class="tw-text-lg tw-text-gray-600 xl:tw-text-xl">Respostas básicas sobre nosso produto.</p>
    </div>
    <div class="tw-mb-12 lg:tw-mb-20">
      <ul class="tw-divide-y tw-divide-gray-300text-base md:tw-text-lg">
      <li>
          <button class="tw-flex tw-items-center tw-justify-between tw-w-full tw-py-3 tw-font-bold lg:tw-py-4 focus:tw-outline-none hover:text-primary-focus">
            <span class="tw-flex-1 tw-pr-6 tw-text-left">
              Para qual tipo de empresa ou serviço é destinado?
            </span>
            <svg class="tw-w-6 tw-h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </button>
        </li>
        <li>
          <button class="tw-flex tw-items-center tw-justify-between tw-w-full tw-py-3 tw-font-bold lg:tw-py-4 focus:tw-outline-none hover:text-primary-focus">
            <span class="tw-flex-1 tw-pr-6 tw-text-left">
              Quem invernizou a barata?
            </span>
            <svg class="tw-w-6 tw-h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </button>
        </li>
        <li>
          <button class="tw-flex tw-items-center tw-justify-between tw-w-full tw-py-3 tw-font-bold lg:tw-py-4 focus:tw-outline-none hover:text-primary-focus">
            <span class="tw-flex-1 tw-pr-6 tw-text-left">
              Qual o nosso propósito nesse planeta?
            </span>
            <svg class="tw-w-6 tw-h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </button>
        </li>
        <li>
          <button class="tw-flex tw-items-center tw-justify-between tw-w-full tw-py-3 tw-font-bold lg:tw-py-4 focus:tw-outline-none hover:text-primary-focus">
            <span class="tw-flex-1 tw-pr-6 tw-text-left">
              Em buraco de cobra tatu caminha dentro?
            </span>
            <svg class="tw-w-6 tw-h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </button>
        </li>
        <li>
          <button class="tw-flex tw-items-center tw-justify-between tw-w-full tw-py-3 tw-font-bold lg:tw-py-4 focus:tw-outline-none hover:text-primary-focus">
            <span class="tw-flex-1 tw-pr-6 tw-text-left">
              Posso experimentar o serviço por algum período gratuitamente?
            </span>
            <svg class="tw-w-6 tw-h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </button>
        </li>
      </ul>
    </div>
    <div class="tw-grid tw-gap-8 md:tw-grid-cols-2 lg:tw-gap-12 ">
      <a href="#" class="tw-flex tw-flex-col tw-p-6 tw-space-y-6 tw-transition-all tw-duration-500 tw-bg-white tw-border tw-border-indigo-100 tw-rounded-lg tw-shadow hover:tw-shadow-xl lg:tw-p-8 lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-6">
        <div class="tw-flex tw-items-center tw-justify-center tw-w-16 tw-h-16 bg-main-color-100 tw-border tw-border-green-200 tw-rounded-full tw-shadow-inner lg:tw-h-20 lg:tw-w-20">
          <svg class="tw-w-10 tw-h-10 text-main-color-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
        </div>
        <div class="tw-flex-1">
          <h5 class="tw-mb-3 tw-text-xl tw-font-bold lg:tw-text-2xl">Compare Planos</h5>
          <p class="tw-mb-6 tw-text-lg tw-text-gray-600">Descubra qual é o melhor plano para sua necessidade</p>
          <span class="tw-flex tw-items-baseline tw-text-lg tw-font-bold text-primary">
            Ver comparação de preços
            <svg class="tw-w-4 tw-h-4 tw-ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
          </span>
        </div>
      </a>
      <a href="#" class="tw-flex tw-flex-col tw-p-6 tw-space-y-6 tw-transition-all tw-duration-500 tw-bg-white tw-border tw-border-indigo-100 tw-rounded-lg tw-shadow hover:tw-shadow-xl lg:tw-p-8 lg:tw-flex-row lg:tw-space-y-0 lg:tw-space-x-6">
        <div class="tw-flex tw-items-center tw-justify-center tw-w-16 tw-h-16 bg-main-color-100 tw-border tw-border-green-200 tw-rounded-full tw-shadow-inner lg:tw-h-20 lg:tw-w-20">
          <svg class="tw-w-10 tw-h-10 text-main-color-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
        </div>
        <div class="tw-flex-1">
          <h5 class="tw-mb-3 tw-text-xl tw-font-bold lg:tw-text-2xl">Precisa de um suporte?</h5>
          <p class="tw-mb-6 tw-text-lg tw-text-gray-600">Tire todas as suas dúvidas com nosso suporte</p>
          <span class="tw-flex tw-items-baseline tw-text-lg tw-font-bold text-primary">
            Fale com nossos profissionais
            <svg class="tw-w-4 tw-h-4 tw-ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
          </span>
        </div>
      </a>
    </div>
  </div>
</div>
