import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'appData'
})
@Injectable({
  providedIn: 'root'
})
export class AppDataPipe implements PipeTransform {
  brand: string;

  constructor() {
    // this.subDomain = window.location.hostname;
    this.brand = environment.brand;
  }

  transform(appName: string): string {
    if (this.brand === 'whatsparts') return 'WhatsParts';
    if (this.brand === 'whatsauto') return 'WhatsAuto';

    return appName;
  }

}
