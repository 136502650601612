<div class="tw-py-12 tw-bg-gray-100 md:tw-py-24">
  <div class="tw-grid tw-max-w-screen-xl tw-px-6 tw-mx-auto lg:tw-px-8 xl:tw-px-4 md:tw-grid-cols-4 xl:tw-grid-cols-5 tw-gap-x-12 lg:tw-gap-x-20">
    <div class="tw-self-center tw-order-2 tw-col-span-2 tw-mt-12 md:tw-order-1 md:tw-mt-0">
      <h1 class="tw-mb-2 tw-text-3xl tw-font-bold tw-text-gray-800 md:tw-text-4xl lg:tw-text-5xl md:tw-mb-4 lg:tw-mb-8">A sua melhor opção em atendimento direto</h1>
      <p class="tw-mb-6 tw-text-lg tw-text-gray-600 xl:tw-text-xl lg:tw-mb-8 xl:tw-mb-10">Integre facilmente em seu ERP ou utilize nossa plataforma completa</p>
      <div class="tw-flex tw-mb-6 tw-space-x-4">
        <input type="text" placeholder="seu email..." class="tw-flex-1 tw-px-4 tw-py-4 tw-leading-none tw-border tw-border-gray-200 tw-rounded-lg focus:tw-outline-none">
        <button class="tw-inline-block tw-px-5 tw-py-2 tw-font-semibold tw-text-white tw-rounded-lg focus:tw-outline-none bg-primary hover:bg-primary-focus">Quero saber mais</button>
      </div>
      <p class="tw-text-sm tw-text-gray-500">Informe seu email para receber mais informações.</p>
    </div>
    <div class="tw-order-1 tw-col-span-2 md:tw-order-2 xl:tw-col-span-3">
      <!-- <img [src]="'demo-flow.png' | assetsSource" [alt]="'WhatsAuto' | appData" class="demo-flow"> -->
      <img [src]="'bg-demo.png' | assetsSource" [alt]="'WhatsAuto' | appData">
    </div>
  </div>
</div>
