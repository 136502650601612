import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'assetsSource'
})
@Injectable({
  providedIn: 'root'
})
export class AssetsSourcePipe implements PipeTransform {
  private subDomain: string;
  private assetsURL: string;

  constructor() {
    this.subDomain = window.location.hostname;
    this.assetsURL = environment.brand;
  }

  transform(imgName: string): string {
    return `assets/${this.assetsURL}/images/${imgName}`;
  }

}
