import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable()
export class AlertService {

  constructor(private toastr: ToastrService) {
    this.toastr.toastrConfig.closeButton = true;
    this.toastr.toastrConfig.newestOnTop = false;
    this.toastr.toastrConfig.progressBar = true;
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.timeOut = 5000;
    this.toastr.toastrConfig.extendedTimeOut = 1000;
    this.toastr.toastrConfig.enableHtml = true;
  }

  error(message: string) {
    this.toastr.error(message);
  }

  warning(message: string) {
    this.toastr.warning(message);
  }

  success(message: string) {
    this.toastr.success(message);
  }

  confirm(title:string, message: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: title,
      html: message,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
      showCloseButton: true,
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        popup: 'w-auto',
        actions: 'mr-2 mb-4',
        confirmButton: 'btn btn-primary mx-3',
        cancelButton: 'btn btn-danger mx-3'
      }
    });
  }

  confirmWithForm(title: string, message: string, inputPlaceholder: string): Promise<SweetAlertResult> {
    const firePayload: any = {
      title: title,
      input: 'text',
      inputPlaceholder: inputPlaceholder,
      html: message,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
      showCloseButton: true,
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        popup: 'w-auto',
        actions: 'mr-2 mb-4',
        confirmButton: 'btn btn-primary mx-3',
        cancelButton: 'btn btn mx-3'
      }
    };

    return Swal.fire(firePayload);
  }
}
