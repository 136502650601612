import { Injectable } from '@angular/core';
import { tokenGetter } from '@shared/utils/token';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { AuthService } from './auth.service';
import { environment } from '@environment/environment';
import { IntegrationType } from '@shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket: any;

  constructor(private authService: AuthService) {}

  loadSocket() {
    const tokenData: any = this.authService.getTokenData();
    const integrationType = Number(tokenData.integrationType);

    const config: SocketIoConfig = {
      url: integrationType === IntegrationType.WHATSAPP_OFFICIAL ? environment.brokerOfficialWSUrl : tokenData.brokerUrl,
      options: {
        transports: ['websocket'],
        reconnection: true,
        query: {
          Authorization: tokenGetter(),
        },
      },
    };

    if (!this.socket) {
      this.socket =  new Socket(config);
      this.socket.connect();

      this.socket.on("connect_error", (err: any) => {
        console.log(`connect_error due to ${err.message}`);
      });
    }
  }

  public getInstance(): any {
    this.loadSocket();
    return this.socket;
  }

}
