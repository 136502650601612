import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { Folder } from '@shared/entities/folder';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseService {
    getMessageStatus(whatsAppMessageId: number): Observable<any> {
        return this.http
          .get<any>(
            `${this.apiUrl}/api/Message/whatsAppMessageId/${whatsAppMessageId}`
          )
          .pipe(map(super.extractData), catchError(super.handleError.bind(this)));
      }

      getMessage(id: number): Observable<any> {
        return this.http
          .get<any>(
            `${this.apiUrl}/api/Message/${id}`
          )
          .pipe(map(super.extractData), catchError(super.handleError.bind(this)));
      }
}